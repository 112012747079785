import { toggleableStateKeptInLocalStorageFactory } from './toggleable-state-kept-in-local-storage-factory';

const MERCHANT_ADMIN_DESIGN_STATE_STORAGE_KEY = 'merchant-admin-design-state';

export class MerchantAdminDesignState extends toggleableStateKeptInLocalStorageFactory(MERCHANT_ADMIN_DESIGN_STATE_STORAGE_KEY) {

	static isV3Mode = MerchantAdminDesignState.isActive;

	static reloadInV3Mode(): void {
		if (MerchantAdminDesignState.isActive)
			return;

		this.set(true);

		globalThis.location.href = '/';
	}

	static reloadInNormalMode(): void {
		if (!MerchantAdminDesignState.isActive)
			return;

		this.set(false);

		globalThis.location.href = '/';
	}

}
