import { TRUE } from './toggleable-state-kept-in-session-storage-factory';

export type ToggleableStateKeptInLocalCtor = (new () => object) & {
	isActive: boolean;
	toggle: () => void;
	toggleAndReloadPage: () => void;
	set: (state: boolean) => void;
	setAndReloadPage: (state: boolean) => void;
};

export function toggleableStateKeptInLocalStorageFactory(localStorageKey: string): ToggleableStateKeptInLocalCtor {

	class ToggleableStateKeptInLocalStorage {

		protected static get _storage(): Storage {
			return localStorage;
		}

		static get isActive(): boolean {
			try {
				return this._storage.getItem(localStorageKey) === TRUE;
			} catch {
				return false;
			}
		}

		static toggle(): void {
			this._setState(null, false);
		}

		static toggleAndReloadPage(): void {
			this._setState(null, true);
		}

		static set(state: boolean): void {
			this._setState(state, false);
		}

		static setAndReloadPage(state: boolean): void {
			this._setState(state, true);
		}

		private static _setState(state: boolean | null, reloadPage: boolean): void {
			const nextIsActiveState = state ?? !this.isActive;

			if (nextIsActiveState === this.isActive)
				return;

			try {
				nextIsActiveState
					? this._storage.setItem(localStorageKey, TRUE)
					: this._storage.setItem(localStorageKey, 'false');
			} catch {
				// ignore
			}

			reloadPage && globalThis.location.reload();
		}

	}

	return ToggleableStateKeptInLocalStorage;
}
